import {
    AUTOCOMPLETE_ENDPOINT,
    parseAutocomplete,
} from '@server/parsers/loop54';
import getSearchEngineUrlByMarket from '@server/utils/getSearchEngineUrlByMarket';
import { cache } from 'react';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';

type Props = {
    query: string;
    market: string;
    searchEngineUser: string;
};

const fetchAutocomplete = cache(async (props: Props) => {
    const { query, market, searchEngineUser } = props;
    if (!query) return null;

    const response = await fetch(
        `${getSearchEngineUrlByMarket(market)}/${AUTOCOMPLETE_ENDPOINT}`,
        {
            method: 'POST',
            headers: {
                'Api-Version': 'V3',
                'User-Id': searchEngineUser,
            },
            body: JSON.stringify({
                query,
                customData: {
                    market,
                    brandResultsOptions: {
                        skip: 0,
                        take: 5,
                    },
                    categoryResultsOptions: {
                        skip: 0,
                        take: 5,
                    },
                    designerResultsOptions: {
                        skip: 0,
                        take: 5,
                    },
                },
                queriesOptions: {
                    take: 50,
                    sortBy: [
                        {
                            type: 'relevance',
                            order: 'asc',
                        },
                    ],
                },
            }),
            next: {
                tags: [REVALIDATION_TAGS.loopAll, REVALIDATION_TAGS.flags],
                revalidate: ONCE_PER_EIGHT_HOURS,
            },
        },
    );

    const search = await response.json();

    return parseAutocomplete(search);
});

export const preload = (props: Props) => {
    void fetchAutocomplete(props);
};

export default fetchAutocomplete;
