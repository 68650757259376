import { graphql } from '@server/gql';

export const updateCheckoutBuyerMutation = graphql(/* GraphQL */ `
    mutation updateCheckoutBuyer(
        $cartId: String!
        $locale: String!
        $buyer: InputCustomer!
    ) {
        checkout: updateCheckoutBuyer(
            locale: $locale
            cartId: $cartId
            buyer: $buyer
        ) {
            cart {
                ...cart
            }
            buyer {
                ...customer
            }
            payer {
                ...customer
            }
            shipTo {
                ...customer
            }
        }
    }
`);
