import type { Loop54Filter, Loop54Payload } from './types';

export default function applyLoopFilter(
    payload: Loop54Payload,
    filter: Loop54Filter[],
) {
    return {
        ...payload,
        resultsOptions: {
            ...payload?.resultsOptions,
            filter: {
                ...payload?.resultsOptions?.filter,
                and: [
                    // @ts-expect-error this is fine
                    ...(payload?.resultsOptions?.filter?.and ?? []),
                    { or: [...filter] },
                ],
            },
        },
    };
}
