import type { LoopFacet } from 'types/loop54';

import { DEFAULT_CATEGORY_ATTRIBUTE } from '@/utils/const';

const FACETS = [
    {
        name: 'Category',
        attributeName: DEFAULT_CATEGORY_ATTRIBUTE,
        selected: [],
        type: 'distinct',
        sortBy: [{ type: 'item', order: 'asc' }],
    },
    { name: 'Price', attributeName: 'Price', type: 'range' },
    {
        name: 'Brand',
        attributeName: 'Brand',
        selected: [],
        type: 'distinct',
        sortBy: [{ type: 'item', order: 'asc' }],
    },
    {
        name: 'Designer',
        attributeName: 'Designers.Value',
        selected: [],
        type: 'distinct',
        sortBy: [{ type: 'item', order: 'asc' }],
    },
] as const;

type Props = {
    facets: LoopFacet[] | undefined;
    givenFacets: LoopFacet[] | undefined;
};
export default function getFacets(props: Props) {
    const { facets, givenFacets } = props;

    return FACETS.map((f) => {
        if (!facets) return f;
        switch (f.name) {
            case 'Category': {
                const givenFacet = givenFacets?.find(
                    (facet) => facet?.name === 'Category',
                );

                if (givenFacet) {
                    return givenFacet;
                }

                const facet = facets?.find(
                    (currentFacet) => currentFacet?.name === 'Category',
                );

                return facet;
            }
            case 'Brand': {
                const givenFacet = givenFacets?.find(
                    (facet) => facet?.name === 'Brand',
                );
                if (givenFacet) {
                    return givenFacet;
                }

                const facet = facets?.find(
                    (currentFacet) => currentFacet?.name === 'Brand',
                );

                return facet;
            }
            case 'Designer': {
                const givenFacet = givenFacets?.find(
                    (facet) => facet?.name === 'Designer',
                );

                if (givenFacet) {
                    return givenFacet;
                }

                const facet = facets?.find(
                    (currentFacet) => currentFacet?.name === 'Designer',
                );

                return facet;
            }
            default: {
                const facet = facets?.find(
                    (currentFacet) => currentFacet?.name === f.name,
                );
                return facet;
            }
        }
    }).filter(Boolean);
}
