import { service } from '@server/client';
import type {
    GetCartAddressQuery,
    GetCartAddressQueryVariables,
} from '@server/gql/graphql';
import { getCartAddressQuery } from '@server/queries/checkout';

export const getCartAddress = async (
    variables: GetCartAddressQueryVariables,
) => {
    try {
        const response = await service<
            GetCartAddressQuery,
            GetCartAddressQueryVariables
        >({
            query: getCartAddressQuery,
            variables,
        });
        return response.cartAddress;
    } catch (error: Error | unknown) {
        console.error('Failed to get cart address', error);
        return undefined;
    }
};
