import { graphql } from '@server/gql';

export const getGiftCardQuery = graphql(/* GraphQL */ `
    query getGiftCard(
        $cartId: String!
        $locale: String!
        $paymentMethodId: Int!
        $giftCard: GiftCard!
    ) {
        getGiftCard(
            cartId: $cartId
            locale: $locale
            paymentMethodId: $paymentMethodId
            giftCard: $giftCard
        ) {
            __typename
            ... on GiftCardCheckResponse {
                balance
                currency
                validFrom
                validTo
            }
        }
    }
`);
