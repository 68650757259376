import { graphql } from '@server/gql';

export const getAdyenCheckoutOrderQuery = graphql(/* GraphQL */ `
    query getAdyenCheckoutOrder(
        $marketCode: String!
        $brandCode: String!
        $sessionId: String!
        $sessionResult: String
        $orderRef: String!
    ) {
        adyenCheckoutOrder(
            sessionId: $sessionId
            orderRef: $orderRef
            sessionResult: $sessionResult
            marketCode: $marketCode
            brandCode: $brandCode
        ) {
            paymentInformation {
                paymentMethod {
                    type
                    name
                    expiry
                    description
                }
                pspReference
                transactionTimestamp
                orderId
            }
            billingInformation {
                firstName
                lastName
                phoneNumber
                email
                address {
                    line1
                    line2
                    zip
                    city
                    country
                }
            }
            shippingInformation {
                firstName
                lastName
                phoneNumber
                email
                address {
                    line1
                    line2
                    zip
                    city
                    country
                }
            }
            cart {
                ...cart
            }
        }
    }
`);
