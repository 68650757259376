import { graphql } from '@server/gql';

export const updateCartMetadataMutation = graphql(/* GraphQL */ `
    mutation updateCartMetadata(
        $metadata: CartMetadataInput!
        $cartId: String!
        $locale: String!
    ) {
        cart: updateCartMetadata(
            metadata: $metadata
            cartId: $cartId
            locale: $locale
        ) {
            ...cart
        }
    }
`);
