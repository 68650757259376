import { parseResult } from '@server/parsers/loop54';
import getSearchEngineUrlByMarket from '@server/utils/getSearchEngineUrlByMarket';
import { PRODUCTS_ENDPOINT } from '@server/utils/parseLoop/const';
import type { Loop54Response } from '@server/utils/parseLoop/types';
import { cache } from 'react';

import getSearchEngineUser from '@/utils/getSearchEngineUser';

const chunkArray = (arr: string[], size: number): string[][] =>
    arr.length > size
        ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
        : [arr];

/**
 * The limit on the search engine appears to be 50 filters - but it always adds some to the count making the real limit ~45 in this case.
 */
const CHUNK_SIZE = 45;

const fetchRelation = cache(async (ids: string[], market: string) => {
    const chunks = chunkArray(ids, CHUNK_SIZE);

    const fetchFn = async (chunk: string[]): Promise<Loop54Response> => {
        const limitedIds = chunk.map((value) => ({
            type: 'id',
            value,
        }));

        const payload = {
            resultsOptions: {
                sortBy: [
                    {
                        type: 'attribute',
                        attributeName: 'ImageURL',
                        order: 'asc',
                    },
                ],
                filter: {
                    and: [
                        {
                            or: limitedIds,
                        },
                        {
                            not: {
                                type: 'attribute',
                                attributeName: 'stockStatus',
                                value: 'OutOfStock',
                            },
                        },
                        { attributeName: `${market}_Price` },
                    ],
                },
            },
            customData: {
                market,
            },
        };

        const url = `${getSearchEngineUrlByMarket(
            market,
        )}/${PRODUCTS_ENDPOINT}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Api-Version': 'V3',
                'User-Id': getSearchEngineUser(),
            },
            body: JSON.stringify(payload),
            cache: 'no-store',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return response.json();
    };

    const responseList: Loop54Response[] = await Promise.all(
        chunks.map((chunkedIds) => fetchFn(chunkedIds)),
    );

    const joinedResults = responseList.reduce(
        (prev: null | Loop54Response, next) => {
            if (prev) {
                return {
                    results: {
                        ...prev?.results,
                        ...next.results,
                    },
                };
            }

            return next;
        },
        null,
    );

    return parseResult(joinedResults as Loop54Response, market).variants;
});

export const preload = (ids: string[], market: string) => {
    void fetchRelation(ids, market);
};

export default fetchRelation;
