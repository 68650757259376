import { graphql } from '@server/gql';

export const updateCheckoutReceiverMutation = graphql(/* GraphQL */ `
    mutation updateCheckoutReceiver(
        $cartId: String!
        $locale: String!
        $receiver: InputCustomer!
    ) {
        checkout: updateCheckoutReceiver(
            locale: $locale
            cartId: $cartId
            receiver: $receiver
        ) {
            cart {
                ...cart
            }
            buyer {
                ...customer
            }
            payer {
                ...customer
            }
            shipTo {
                ...customer
            }
        }
    }
`);
