import { graphql } from '@server/gql';

export const removeFromCartMutation = graphql(/* GraphQL */ `
    mutation removeFromCart(
        $cartId: String!
        $locale: String!
        $partNo: String!
    ) {
        cart: removeFromCart(
            cartId: $cartId
            locale: $locale
            partNo: $partNo
        ) {
            ...cart
        }
    }
`);
