import getSearchEngineUrlByMarket from '@server/utils/getSearchEngineUrlByMarket';
import { PRODUCTS_ENDPOINT } from '@server/utils/parseLoop/const';
import parsePayload from '@server/utils/parseLoop/parseLoopPayload';
import { parseLoopResponse } from '@server/utils/parseLoop/parseLoopResponse';
import type { BasicCommerceData, Filter } from '@server/utils/parseLoop/types';
import { cache } from 'react';
import type { CommerceData, Sort } from 'types/common';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import { setLoopCacheOptions } from '@/utils/cache/setLoopCacheOptions';
import { DEFAULT_FILTER } from '@/utils/filters';
import getSearchEngineUser from '@/utils/getSearchEngineUser';

export type Props = {
    skip?: number;
    take?: number;
    sort?: Sort;
    market: string;
    filters?: Filter[];
    commerceData?: CommerceData[];
    category?: string;
    defaultFilter?: any[];
    basicCommerceData?: BasicCommerceData | null;
};

const fetchProducts = cache(async (props: Props) => {
    const {
        filters: _filters = [],
        defaultFilter: _propsDefaultFilter,
        ...rest
    } = props;

    const { payload, filters } = parsePayload({
        filters: [...(_propsDefaultFilter || DEFAULT_FILTER), ..._filters],
        ...rest,
    });

    let url = `${getSearchEngineUrlByMarket(
        props.market,
    )}/${PRODUCTS_ENDPOINT}`;

    if (payload.attribute) {
        if (payload.attribute.value) url += 'ByAttribute';
        else delete payload.attribute;
    }

    const productIds = props.commerceData?.[0]?.items;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Api-Version': 'V3',
            'User-Id': getSearchEngineUser(),
        },
        body: JSON.stringify(payload),
        ...(productIds
            ? setLoopCacheOptions({
                  revalidate: ONCE_PER_EIGHT_HOURS,
                  tags: [
                      REVALIDATION_TAGS.loopAll,
                      ...productIds.map(REVALIDATION_TAGS.productById),
                  ],
              })
            : { cache: 'no-store' }),
    })
        .then((response) => response?.json())
        .catch((error) => console.log(error));

    if (!response) {
        return undefined;
    }

    return parseLoopResponse(response, filters, props.commerceData);
});

export const preload = (props: Props) => {
    void fetchProducts(props);
};

export default fetchProducts;
