import { encode } from 'punycode';

export const ONCE_PER_DAY = 86400;
export const ONCE_PER_EIGHT_HOURS = 28800;

export const REVALIDATION_TAGS = {
    // Contentful
    page: 'page',
    siteinfo: 'siteinfo',
    keyValue: 'keyValue',
    dictionary: 'dictionary',
    campaign: 'campaign',
    editorialPage: 'editorialPage',
    landingPage: 'landingPage',
    sideMenu: 'sideMenu',
    header: 'header',
    store: 'store',
    productPageInfo: 'productPageInfo',
    navigation: 'navigation',
    navigationLink: 'navigationLink',
    form: 'form',
    pageBySlug: (slug: string) => encode(`page:${slug}`),
    pageByType: (type: string) => encode(`pageType:${type}`),

    // Loop
    loopAll: 'loop:all',
    flags: 'loop:flags',
    productById: (id: string) => encode(`loop:productById:${id}`),
    productByMainURL: (url: string) => encode(`loop:productByMainUrl:${url}`),
    brandByCode: (code: string) => encode(`loop:brandByCode:${code}`),
    designerByCode: (code: string) => encode(`loop:designerByCode:${code}`),
    categoryByCode: (code: string) => encode(`loop:categoryByCode:${code}`),
} as const;
