import { graphql } from '@server/gql';

export const updateCheckoutPayerMutation = graphql(/* GraphQL */ `
    mutation updateCheckoutPayer(
        $cartId: String!
        $locale: String!
        $payer: InputCustomer!
    ) {
        checkout: updateCheckoutPayer(
            locale: $locale
            cartId: $cartId
            payer: $payer
        ) {
            cart {
                ...cart
            }
            buyer {
                ...customer
            }
            payer {
                ...customer
            }
            shipTo {
                ...customer
            }
        }
    }
`);
