import { graphql } from '@server/gql';

export const getCheckoutPaymentsQuery = graphql(/* GraphQL */ `
    query getCheckoutPayments($locale: String!, $cartId: String!) {
        checkout(locale: $locale, cartId: $cartId) {
            payments {
                paymentCode
                amount
                cardNo
                paymentMethodId
                paymentServiceId
            }
        }
    }
`);
