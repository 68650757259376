import type { Product, Variant } from 'types/product';

import type { ProductQueries } from '@/utils/product/types';

import fetchRelation from './fetchRelation';

type ComparableSelector<T extends any[]> = T extends (infer U)[]
    ? keyof U
    : never;

const lowestItem = (
    items: Variant[],
    comparable: ComparableSelector<Variant[]>,
) =>
    items?.reduce(
        (prev, curr) => (prev[comparable] <= curr[comparable] ? prev : curr),
        items?.[0],
    );

const getLightsource = (
    lightsources: (Variant & { isRecommended: boolean })[],
    params?: ProductQueries,
) => {
    if (typeof params?.lightsource === 'string') {
        if (params?.lightsource === 'no-lightsource') {
            return null;
        }

        return lightsources.find((ls) => ls?.id === params.lightsource);
    }

    const recommended = lightsources?.filter((ls) => ls?.isRecommended);

    return lowestItem(recommended ?? lightsources, 'price');
};

export type Lightsources = {
    lightsources?: Variant[];
    lightsource?: Variant;
};

const fetchLightsources = async (
    product: Product,
    market: string,
    params?: ProductQueries,
) => {
    const recommendedIds = product.recommendedLightsources ?? [];
    const relatedIds = product.relatedLightsources ?? [];
    const ids = [...new Set([...recommendedIds, ...relatedIds])];

    if (!ids.length) return { lightsource: undefined, lightsources: [] };

    const fetchedLightsources = await fetchRelation(ids.slice(0, 16), market);

    const lightsources = fetchedLightsources.map((lightsource) => ({
        ...lightsource,
        isRecommended: recommendedIds.includes(lightsource.id),
    }));
    const lightsource = getLightsource(lightsources, params);

    return {
        lightsources,
        lightsource,
    };
};
export default fetchLightsources;
