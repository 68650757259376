import { parseLoopResponse } from '@server/utils/parseLoop/parseLoopResponse';
import type {
    BasicCommerceData,
    Filter,
    Loop54Filter,
} from '@server/utils/parseLoop/types';
import { cache } from 'react';
import type { CommerceData, Sort } from 'types/common';
import type { LoopFacet } from 'types/loop54';

import fetchCategoryFromLoop from './fetchCategoryFromLoop';

export type Attribute = {
    name: string;
    value: string[];
};

type Props = {
    skip?: number;
    take?: number;
    sort?: Sort;
    attribute?: Attribute;
    market: string;
    applyCustomFilter?: Loop54Filter[];
    filters?: Filter[];
    commerceData?: CommerceData[];
    basicCommerceData?: BasicCommerceData | null;
    category?: string;
    slug?: string | null;
    defaultFilter?: any[];
    defaultSort?: Sort;
    searchEngineUser: string;
    facets?: LoopFacet[] | undefined;
    limitResponse?: boolean;
};

const fetchCategory = cache(async (props: Props) => {
    const { items, parsedFilters } = await fetchCategoryFromLoop(props);

    return parseLoopResponse(items, parsedFilters);
});

export const preload = (props: Props) => {
    void fetchCategory(props);
};

export default fetchCategory;
