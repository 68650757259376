import type { BaseFilter, EnrichedFilter } from 'types/filter';
import type { Image } from 'types/image';
import type {
    Item,
    Loop54AutocompleteResponse,
    Loop54AutocompleteResponseLink,
    Loop54Response,
} from 'types/loop54';
import type { Product, Variant } from 'types/product';

type Props = {
    filter?: (BaseFilter | EnrichedFilter)[];
};

export const AUTOCOMPLETE_ENDPOINT = 'autoComplete';

export const PRODUCTS_BY_CATEGORY_ENDPOINT = 'getEntitiesByAttribute';
export const COMPLEMENTARY_PRODUCTS_ENDPOINT = 'getComplementaryEntities';
export const SEARCH_ENDPOINT = 'search';

/**
 * Duplicated - use KEYS from _api/utils/parseLoop/const.ts instead.
 *
 * @deprecated
 */
export const KEYS = {
    HEIGHT_KEY: 'height_cm',
    WIDTH_KEY: 'width_cm',
    DEPTH_KEY: 'depth_cm',
    WEIGHT_KEY: 'weight_cm',
    COLOR_KEY: 'norce_color',
    ID_KEY: 'Id',
    VARIANT_ID_KEY: 'Code',
    TYPE_KEY: 'type',
    NAME_KEY: 'Name',
    IMAGE_KEY: 'Images',
    DESCRIPTION_KEY: 'Description',
    GROUP_KEY: 'GroupId',
    CATEGORY_KEY: 'CategoryPath.Code',
    DESIGNER_KEY: 'Designer',
    BRAND_KEY: 'Brand',
    FLAGS_KEY: 'Flags',
    VARIANT_FLAGS_KEY: 'ArticleStatusFlag',
    STOCK_KEY: 'InStock',
    CAMPAIGN_KEY: 'Campaign',
    PRICE_KEY: 'Price',
    FROM_PRICE_KEY: 'FromPrice',
    CAMPAIGN_PRICE_KEY: 'CampaignPrice',
    CAMPAIGN_FROM_PRICE_KEY: 'CampaignFromPrice',
    NUMBER_OF_VARIANTS_KEY: 'NrOfVariants',
    VARIANT_ATTRIBUTES_KEY: 'VariantDefiningAttributes',
    VARIANT_ATTRIBUTES_NAMES_KEY: 'VariantDefiningAttributesNames',
    RECOMMENDED_LIGHTSOURCES_KEY: 'Rekommenderad ljuskälla',
    RELATED_LIGHTSOURCES_KEY: 'Ljuskälla',
    RELATED_PRODUCTS_KEY: 'Tillbehör',
    LIGHTSOURCES_QUANTITY_KEY: 'norce_light_source_quantity',
    DELIVERY_MIN_KEY: 'MinDeliveryTime',
    DELIVERY_MAX_KEY: 'MaxDeliveryTime',
    DELIVERY_UNIT_KEY: 'DeliveryUnit',
    STOCK_STATUS_KEY: 'StockStatus',
    STOCK_QUANTITY_KEY: 'StockQuantity',
};

/**
 * Duplicated - use _api/utils/parseLoop/parseVariant.ts instead.
 *
 * @deprecated
 */
export const parseVariant = ({ attributes, id }: Item): Variant => {
    const variant: Partial<Variant> = {};
    for (let i = 0; i < attributes?.length; i += 1) {
        const { name, values } = attributes[i];
        if (name === KEYS.VARIANT_ID_KEY) variant.id = values[0] as string;
        else if (name === KEYS.NAME_KEY) variant.title = values[0] as string;
        else if (name === KEYS.GROUP_KEY) variant.groupId = values[0] as string;
        else if (name === KEYS.PRICE_KEY) variant.price = Number(values[0]);
        // else if (name === KEYS.CAMPAIGN_PRICE_KEY)
        //     variant.campaignPrice = Number(values[0]);
        else if (name === KEYS.CAMPAIGN_KEY)
            variant.campaign = values[0] as boolean;
        else if (name === KEYS.DELIVERY_MIN_KEY)
            variant.deliveryMin = Number(values[0]); // TEMP
        else if (name === KEYS.DELIVERY_MAX_KEY)
            variant.deliveryMax = Number(values[0]); // TEMP
        else if (name === KEYS.DELIVERY_UNIT_KEY)
            variant.deliveryUnit = values[0] as string;
        else if (name === KEYS.STOCK_QUANTITY_KEY)
            variant.stockQuantity = values[0] as number;
        else if (name === KEYS.FLAGS_KEY) variant.flags = values as string[];
        else if (name === KEYS.VARIANT_FLAGS_KEY)
            variant.flag = values as string[];
        else if (name === KEYS.LIGHTSOURCES_QUANTITY_KEY)
            variant.lightsourceQuantity = Number(values[0]);
        else if (name === KEYS.STOCK_STATUS_KEY) {
            variant.stockStatus = values[0] as string;
            variant.stock = values[0] === 'StockItem';
        } else if (name === KEYS.IMAGE_KEY) {
            variant.images = values.map(
                (src, index): Image => ({
                    src: src as string,
                    id: `${id}_${index}`,
                    type: 'image',
                    alt: `${id} #${index}`,
                }),
            );
        } else variant[name] = values.length > 1 ? values : values[0];
    }
    return variant as Variant;
};

/**
 * Duplicated - use _api/utils/parseLoop/parseProduct.ts instead.
 *
 * @deprecated
 */
export const parseProduct = ({ attributes, id }: Item): Product => {
    const product: Partial<Product> = {};
    for (let i = 0; i < attributes?.length; i += 1) {
        const { name, values } = attributes[i];
        if (name === KEYS.ID_KEY) {
            product.id = values[0] as string;
            product.slug = values[0] as string;
        } else if (name === KEYS.NAME_KEY) product.title = values[0] as string;
        else if (name === KEYS.BRAND_KEY) product.brand = values[0] as string;
        else if (name === KEYS.DESIGNER_KEY)
            product.designer = values[0] as string;
        else if (name === KEYS.GROUP_KEY) product.groupId = values[0] as string;
        else if (name === KEYS.DESCRIPTION_KEY)
            product.description = values[0] as string;
        else if (name === KEYS.CATEGORY_KEY)
            product.category = values[0] as string;
        else if (name === KEYS.STOCK_KEY)
            product.hasStock = values[0] as boolean;
        else if (name === KEYS.FLAGS_KEY) product.flags = values as string[];
        else if (name === KEYS.NUMBER_OF_VARIANTS_KEY)
            product.hasVariants = (values[0] as number) > 1;
        else if (name === KEYS.CAMPAIGN_KEY)
            product.hasCampaign = values[0] as boolean;
        else if (name === KEYS.RELATED_PRODUCTS_KEY)
            product.relatedProducts = values as string[];
        else if (name === KEYS.RELATED_LIGHTSOURCES_KEY)
            product.relatedLightsources = values as string[];
        else if (name === KEYS.RECOMMENDED_LIGHTSOURCES_KEY)
            product.recommendedLightsources = values as string[];
        else if (name === KEYS.LIGHTSOURCES_QUANTITY_KEY)
            product.lightsourceQuantity = Number(values[0]);
        else if (name === KEYS.PRICE_KEY) product.price = values[0] as number;
        // else if (name === KEYS.CAMPAIGN_PRICE_KEY) product.campaignPrice = values[0] as number;
        else if (name === KEYS.VARIANT_ATTRIBUTES_KEY) {
            const entries = Object.entries(JSON.parse(values[0] as string));
            product.variantAttributes = entries.map(([key, value]) => ({
                key,
                ...(value as any),
            }));
        } else if (name === KEYS.STOCK_STATUS_KEY) {
            product.stockStatus = values[0] as string;
            product.stock = values[0] === 'StockItem';
        } else if (name === KEYS.FROM_PRICE_KEY) {
            product.price = values[0] as number;
            product.fromPrice = true;
            // } else if (name === KEYS.CAMPAIGN_FROM_PRICE_KEY) {
            //     product.campaignPrice = Number(values[0]);
            //     product.campaignFromPrice = true;
        } else if (name === KEYS.IMAGE_KEY) {
            product.images = values.map(
                (src, index): Image => ({
                    src: src as string,
                    id: `${id}_${index}`,
                    type: 'image',
                    alt: `${id} #${index}`,
                }),
            );
        } else product[name] = values.length > 1 ? values : values[0];
    }
    return product as Product;
};

export const parseAutocomplete = (
    response: Loop54Response,
): Loop54AutocompleteResponse => {
    const { customData, queries, scopedQuery } = response;
    const brands =
        customData?.brandResults.results.items.map(({ attributes }) =>
            attributes.reduce(
                (a, b) => ({
                    ...a,
                    [b.name]: b.values[0],
                }),
                {} as Loop54AutocompleteResponseLink,
            ),
        ) ?? [];
    const categories =
        customData?.categoryResults.results.items.map(({ attributes }) =>
            attributes.reduce(
                (a, b) => ({
                    ...a,
                    [b.name]: b.values[0],
                }),
                {} as Loop54AutocompleteResponseLink,
            ),
        ) ?? [];
    const designers =
        customData?.designerResults.results.items.map(({ attributes }) =>
            attributes.reduce(
                (a, b) => ({
                    ...a,
                    [b.name]: b.values[0],
                }),
                {} as Loop54AutocompleteResponseLink,
            ),
        ) ?? [];
    const scopes =
        scopedQuery?.scopes.map((item) => ({
            query: scopedQuery.query,
            category: item,
        })) ?? [];
    return {
        brands,
        categories,
        designers,
        scopes,
        queries: queries?.items.map((i) => i.query) ?? [],
    };
};

export const parseResult = (
    response: Loop54Response,
    market: string,
): { product: Product; variants: Variant[]; attributes: Map<string, any> } => {
    const { results } = response;
    const { items: _items } = results || {};

    const products = _items.filter((item) => item.type === 'Product');
    const _variants = _items.filter((item) => item.type === 'Variant');

    const [product] = products.map(parseProduct);
    const variants = _variants.map(parseVariant);

    const attributes = new Map<string, any>();
    if (!product?.variantAttributes?.length)
        return {
            product,
            variants,
            attributes,
        };

    for (let i = 0; i < variants.length; i += 1) {
        const {
            price,
            stock,
            Flags = [],
            campaignPrice,
            stockStatus,
        } = variants[i];
        const campaign = Flags.includes(`campaign_${market}`);
        let path = '';
        for (let j = 0; j < product.variantAttributes?.length; j += 1) {
            const { key } = product.variantAttributes[j];
            const id = `${path}${key}=${variants[i][key]}`;
            if (attributes.has(id)) {
                const variant = attributes.get(id) as any;
                attributes.set(id, {
                    ...variant,
                    price: [...new Set([...variant.price, price])],
                    stock: [...new Set([...variant.stock, stock ?? false])],
                    campaign: [
                        ...new Set([...variant.campaign, campaign ?? false]),
                    ],
                    stockStatus: [
                        ...new Set([...variant.stockStatus, stockStatus]),
                    ],
                    ...(campaignPrice && {
                        campaignPrice: [
                            ...new Set([
                                ...(variant?.campaignPrice
                                    ? variant.campaignPrice
                                    : []),
                                campaignPrice,
                            ]),
                        ],
                    }),
                });
            } else {
                attributes.set(id, {
                    price: [price],
                    stock: [stock ?? false],
                    campaign: [campaign ?? false],
                    stockStatus: [stockStatus],
                    ...(campaignPrice && { campaignPrice: [campaignPrice] }),
                });
            }
            path = `${id}&`;
        }
    }

    return {
        product,
        variants,
        attributes,
    };
};

export const parseResults = (response: Loop54Response, props?: Props) => {
    const { results, relatedResults } = response;
    const { facets, items: _items, count } = results || {};
    const items = _items?.map(parseProduct);
    const relatedItems = relatedResults?.items?.map(parseProduct) ?? [];
    const filter: EnrichedFilter[] = [];

    for (let i = 0; i < facets?.length; i += 1) {
        const _filter = props?.filter?.find((f) => f.name === facets?.[i].name);
        const _facet = facets?.[i];

        if (_filter) {
            if (_filter.type === 'range' && _facet.type === 'range') {
                filter.push({
                    min: _facet.min,
                    max: _facet.max,
                    type: 'range',
                    show: true,
                    name: _filter.name,
                    attribute: _filter.attribute,
                    values: [
                        _facet?.selectedMin ?? 0,
                        _facet?.selectedMax ?? 10000000,
                    ],
                });
            } else if (
                _filter.type === 'checkbox' &&
                _facet.type === 'distinct'
            ) {
                filter.push({
                    type: 'checkbox',
                    show: true,
                    name: _filter.name,
                    items: _facet.items?.map((o) => ({
                        name: o.item,
                        count: o.count,
                    })),
                    attribute: _filter.attribute,
                    values: _filter.values,
                });
            }
        }
    }

    return {
        filter,
        items,
        relatedItems,
        count,
    };
};
