import { PRODUCTS_BY_CATEGORY_ENDPOINT } from '@server/parsers/loop54';
import getSearchEngineUrlByMarket from '@server/utils/getSearchEngineUrlByMarket';
import { KEYS } from '@server/utils/parseLoop/const';
import { parseLoopResponse } from '@server/utils/parseLoop/parseLoopResponse';
import type {
    Loop54Filter,
    Loop54Response,
} from '@server/utils/parseLoop/types';
import type { Product, Variant } from 'types/product';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import { setLoopCacheOptions } from '@/utils/cache/setLoopCacheOptions';
import getSearchEngineUser from '@/utils/getSearchEngineUser';

const defaultOptions = {
    method: 'POST',
    headers: {
        'Api-Version': 'V3',
        'User-Id': getSearchEngineUser(),
    },
};

type Props = {
    endpointHash: string;
    limit: number;
    market: string;
    brand: string;
    categories: string[];
    id: string;
};

export default async function fetchBrandProducts(props: Props) {
    const {
        market,
        endpointHash,
        limit,
        brand,
        categories: categoryValues,
        id: productId,
    } = props;

    if (!brand) return [];

    const filter: Loop54Filter = {
        and: [
            {
                type: 'type',
                value: 'Product',
            },
            {
                type: 'attribute',
                attributeName: KEYS.BRAND_CODE_KEY,
                value: brand,
            },
            {
                not: {
                    type: 'id',
                    value: productId,
                },
            },
            { attributeName: `${market}_Price` },
        ],
    };

    const url = `${getSearchEngineUrlByMarket(
        market,
    )}/${PRODUCTS_BY_CATEGORY_ENDPOINT}#id=${endpointHash}`;

    const fnFetch = async (): Promise<(Product | Variant)[] | undefined> => {
        const category = categoryValues?.pop();
        const payload = {
            attribute: {
                ...(category
                    ? {
                          name: KEYS.CATEGORY_CODE_KEY,
                          value: category,
                      }
                    : {
                          name: KEYS.BRAND_CODE_KEY,
                          value: brand,
                      }),
            },
            customData: {
                market,
            },
            resultsOptions: {
                skip: 0,
                take: limit,
                filter,
            },
        };
        const response = await fetch(url, {
            ...defaultOptions,
            body: JSON.stringify(payload),
            ...setLoopCacheOptions({
                revalidate: ONCE_PER_EIGHT_HOURS,
                tags: [
                    REVALIDATION_TAGS.loopAll,
                    category
                        ? REVALIDATION_TAGS.categoryByCode(category)
                        : REVALIDATION_TAGS.brandByCode(brand),
                    REVALIDATION_TAGS.productById(productId),
                ],
            }),
        });
        if (!response.ok) {
            console.debug('Failed to fetch related brand products');
            return [];
        }
        const data: Loop54Response = await response.json();
        if (data.results.count >= limit) {
            return parseLoopResponse(data, []).items;
        }
        if (!category) {
            console.debug('Failed to fetch related brand products');
            return undefined;
        }
        return fnFetch();
    };

    return fnFetch();
}
