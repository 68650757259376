import {
    parseResults,
    PRODUCTS_BY_CATEGORY_ENDPOINT,
} from '@server/parsers/loop54';
import getSearchEngineUrlByMarket from '@server/utils/getSearchEngineUrlByMarket';
import type { Loop54Payload } from '@server/utils/parseLoop/types';
import { cache } from 'react';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import { DEFAULT_CATEGORY_ATTRIBUTE } from '@/utils/const';
import getSearchEngineUser from '@/utils/getSearchEngineUser';

const POPULAR_CATEGORIES = ['NG_MÖBEL', 'NG_BELYSNING', 'NG_UTEMÖBEL'];

const fetchPopular = cache(async (market: string, take = 8) => {
    const payload: Loop54Payload = {
        attribute: {
            name: DEFAULT_CATEGORY_ATTRIBUTE,
            value: POPULAR_CATEGORIES,
        },
        resultsOptions: {
            take,
            filter: {
                and: [
                    {
                        type: 'type',
                        value: 'Product',
                    },
                    { attributeName: `${market}_Price` },
                ],
            },
            sortBy: [
                {
                    type: 'popularity',
                    order: 'desc',
                },
            ],
            skip: 0,
            facets: [],
        },
        customData: {
            market,
        },
    };
    const url = `${getSearchEngineUrlByMarket(
        market,
    )}/${PRODUCTS_BY_CATEGORY_ENDPOINT}`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Api-Version': 'V3',
            'User-Id': getSearchEngineUser(),
        },
        body: JSON.stringify(payload),
        next: {
            tags: [
                REVALIDATION_TAGS.loopAll,
                ...POPULAR_CATEGORIES.map(REVALIDATION_TAGS.categoryByCode),
            ],
            revalidate: ONCE_PER_EIGHT_HOURS,
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const items = await response.json();

    return parseResults(items);
});

export const preload = (market: string, take = 8) => {
    void fetchPopular(market, take);
};

export default fetchPopular;
