import { graphql } from '@server/gql';

export const confirmPaymentSuccessfulQuery = graphql(/* GraphQL */ `
    query confirmPaymentSuccessful(
        $paymentService: String!
        $paymentRef: String!
        $locale: String!
    ) {
        confirmPaymentSuccessful(
            paymentService: $paymentService
            paymentRef: $paymentRef
            locale: $locale
        ) {
            status
            statusDescription
            orderNo
            html
            parameters {
                name
                value
            }
        }
    }
`);
