import getSearchEngineUrlByMarket from '@server/utils/getSearchEngineUrlByMarket';
import { PRODUCTS_ENDPOINT } from '@server/utils/parseLoop/const';
import { parseResult } from '@server/utils/parseLoop/parseLoopResponse';
import type { LoopFetchProductResult } from '@server/utils/parseLoop/types';
import { cache } from 'react';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import { setLoopCacheOptions } from '@/utils/cache/setLoopCacheOptions';
import getSearchEngineUser from '@/utils/getSearchEngineUser';
import type { ProductQueries } from '@/utils/product/types';

const fetchProduct = cache(
    async (
        slug: string,
        market?: string,
        filter?: ProductQueries,
    ): Promise<LoopFetchProductResult> => {
        const url = `${getSearchEngineUrlByMarket(
            market,
        )}/${PRODUCTS_ENDPOINT}`;
        const resultsOptions = {
            take: 1000,
            filter: {
                and: [
                    {
                        type: 'attribute',
                        attributeName: 'MainProductURL',
                        value: slug.startsWith('/') ? slug : `/${slug}`,
                        comparisonMode: 'equals',
                    },
                    { attributeName: `${market}_Price` },
                ],
            },
        };
        return fetch(url, {
            method: 'POST',
            headers: {
                'Api-Version': 'V3',
                'User-Id': getSearchEngineUser(),
            },
            body: JSON.stringify({ resultsOptions, customData: { market } }),
            ...setLoopCacheOptions({
                revalidate: ONCE_PER_EIGHT_HOURS,
                tags: [
                    REVALIDATION_TAGS.loopAll,
                    REVALIDATION_TAGS.productByMainURL(slug),
                ],
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.results?.count === 0) return result;
                return parseResult(result, market, filter);
            });
    },
);

export const preload = (
    slug: string,
    market?: string,
    filter?: ProductQueries,
) => {
    void fetchProduct(slug, market, filter);
};

export default fetchProduct;
