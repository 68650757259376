import { envConfig } from './env';

export const FEATURE_FLAGS = {
    checkout: {
        giftcards: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_CHECKOUT_GIFTCARDS,
        ),
        adyen: isFeatureEnabled(envConfig.NEXT_PUBLIC_FEATURES_CHECKOUT_ADYEN),
        giftCardChangeButton: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_CHECKOUT_GIFTCARDS_CHANGE_BUTTON,
        ),
    },
    customerService: {
        emailLink: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_CUSTOMER_SERVICE_EMAIL_LINK,
        ),
        phoneLink: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_CUSTOMER_SERVICE_PHONE_LINK,
        ),
        imboxButton: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_CUSTOMER_SERVICE_IMBOX_BUTTON,
        ),
    },
    discountPopup: {
        show: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_SHOW_DISCOUNT_POPUP,
        ),
    },
    searchEngine: {
        exploded: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_SEARCH_ENGINE_EXPLODE_PRODUCTS,
        ),
    },
    products: {
        swatches: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_PRODUCTS_SWATCHES,
        ),
        videos: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_PRODUCTS_VIDEOS,
        ),
        staticPages: isFeatureEnabled(
            envConfig.NEXT_PUBLIC_FEATURES_STATIC_PRODUCT_PAGES,
        ),
    },
    cache: {
        loop: isFeatureEnabled(envConfig.NEXT_PUBLIC_FEATURES_CACHE_LOOP),
    },
} as const;

function isFeatureEnabled(value?: string): boolean {
    const compare = value?.localeCompare('true', undefined, {
        sensitivity: 'accent',
    });

    return compare === 0;
}
