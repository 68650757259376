import { service } from '@server/client';
import type {
    UpdateCheckoutPayerMutation,
    UpdateCheckoutPayerMutationVariables,
} from '@server/gql/graphql';
import { updateCheckoutPayerMutation } from '@server/queries/checkout';

export const updateCheckoutPayer = async (
    variables: UpdateCheckoutPayerMutationVariables,
) => {
    try {
        const response = await service<
            UpdateCheckoutPayerMutation,
            UpdateCheckoutPayerMutationVariables
        >({
            query: updateCheckoutPayerMutation,
            variables,
        });

        return response.checkout;
    } catch (error: Error | unknown) {
        console.error('Error in updateCheckoutPayer', error);
    }
};
