import { service } from '@server/client';
import type {
    GetCheckoutQuery,
    GetCheckoutQueryVariables,
} from '@server/gql/graphql';
import { getCheckoutQuery } from '@server/queries/checkout';

export const getCheckout = async (variables: GetCheckoutQueryVariables) => {
    try {
        const response = await service<
            GetCheckoutQuery,
            GetCheckoutQueryVariables
        >({
            query: getCheckoutQuery,
            variables,
        });

        return response.checkout;
    } catch (error: Error | unknown) {
        console.error('Error in getCheckout', error);
        throw error;
    }
};
