import { graphql } from '@server/gql';

export const addToCartMutation = graphql(/* GraphQL */ `
    mutation addToCart(
        $locale: String!
        $cartId: String!
        $items: [CartItemInput!]!
    ) {
        cart: addToCart(locale: $locale, cartId: $cartId, items: $items) {
            ...cart
        }
    }
`);
