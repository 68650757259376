import { getCookie } from 'cookies-next';
import { z } from 'zod';

import { CART_COOKIE } from '@/config/cart';

export function getCartCookie() {
    const cartCookie = getCookie(CART_COOKIE);

    const validation = z.string().safeParse(cartCookie);

    if (validation.error) {
        return;
    }

    const id = validation.data.split(':').shift();
    const token = validation.data.split(':').pop();
    const value = validation.data;

    return {
        id,
        token,
        value,
    };
}
