import { graphql } from '@server/gql';

export const getCheckoutDeliveryQuery = graphql(/* GraphQL */ `
    query getCheckoutDelivery(
        $cartId: String!
        $zipCode: String!
        $locale: String!
    ) {
        deliveries(cartId: $cartId, locale: $locale, zipCode: $zipCode) {
            deliveryCheckoutId
            currency
            language
            addons {
                id
                description
                selected
                priceDescription
                price
            }
            options {
                id
                name
                description1
                description2
                description3
                description4
                description5
                description6
                carrierId
                priceDescription
                priceValue
                defaultPrice
                taxRate
                serviceId
                category
                noDefaultAgent
                agents {
                    id
                    name
                    address1
                    address2
                    zipCode
                    city
                    state
                    country
                    mapLongitude
                    mapLatitude
                    phone
                    email
                    additionalInfo
                    flags
                    openingHourWeekdays {
                        weekday
                        description
                        closed
                        hours {
                            start
                            stop
                        }
                    }
                    openingHourSpecialDays {
                        date
                        description
                        closed
                        hours {
                            start
                            stop
                        }
                    }
                    distance
                    estimatedDate
                    estimatedTime
                }
                fields {
                    id
                    description
                    type
                    initialValue
                    mandatory
                    min
                    max
                    pattern
                }
                addons {
                    id
                    description
                    selected
                    priceDescription
                    price
                    oneOfs
                    atLeastOneOfs
                    excludes
                    fields {
                        id
                        description
                        type
                        initialValue
                        mandatory
                        min
                        max
                        pattern
                    }
                    genericKeyValues {
                        key
                        value
                    }
                }
                features {
                    id
                    name
                    description
                    color
                    url
                    iconId
                }
                certifications {
                    id
                    name
                    description
                    color
                    iconId
                }
                genericKeyValues {
                    key
                    value
                }
                subOptions {
                    id
                    name
                    description1
                    description2
                    description3
                    description4
                    description5
                    description6
                    carrierId
                    priceDescription
                    priceValue
                    defaultPrice
                    taxRate
                    serviceId
                    category
                    noDefaultAgent
                    agents {
                        id
                        name
                        address1
                        address2
                        zipCode
                        city
                        state
                        country
                        mapLongitude
                        mapLatitude
                        phone
                        email
                        additionalInfo
                        flags
                        openingHourWeekdays {
                            weekday
                            description
                            closed
                            hours {
                                start
                                stop
                            }
                        }
                        openingHourSpecialDays {
                            date
                            description
                            closed
                            hours {
                                start
                                stop
                            }
                        }
                        distance
                        estimatedDate
                        estimatedTime
                    }
                    fields {
                        id
                        description
                        type
                        initialValue
                        mandatory
                        min
                        max
                        pattern
                    }
                    addons {
                        id
                        description
                        selected
                        priceDescription
                        price
                        oneOfs
                        atLeastOneOfs
                        excludes
                        fields {
                            id
                            description
                            type
                            initialValue
                            mandatory
                            min
                            max
                            pattern
                        }
                        genericKeyValues {
                            key
                            value
                        }
                    }
                    features {
                        id
                        name
                        description
                        color
                        url
                        iconId
                    }
                    certifications {
                        id
                        name
                        description
                        color
                        iconId
                    }
                    genericKeyValues {
                        key
                        value
                    }
                }
            }
        }
    }
`);
