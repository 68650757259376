import { graphql } from '@server/gql';

export const removeGiftCardMutation = graphql(/* GraphQL */ `
    mutation removeGiftCard(
        $cartId: String!
        $locale: String!
        $paymentCode: String!
    ) {
        removeGiftCard(
            cartId: $cartId
            locale: $locale
            paymentCode: $paymentCode
        ) {
            status
        }
    }
`);
