import { service } from '@server/client';
import type {
    GetCheckoutFormQuery,
    GetCheckoutFormQueryVariables,
} from '@server/gql/graphql';
import { getCheckoutFormQuery } from '@server/queries/checkout';

export const getCheckoutForm = async (
    variables: GetCheckoutFormQueryVariables,
) => {
    try {
        const response = await service<
            GetCheckoutFormQuery,
            GetCheckoutFormQueryVariables
        >({
            query: getCheckoutFormQuery,
            variables,
        });

        return response.checkoutForm;
    } catch (error: Error | unknown) {
        console.error('Error in getCheckoutForm', error);
        throw error;
    }
};
