/**
 * This regexp will match a forward slash, followed by one or more digits, in the end of the string only.
 */
export const SLASH_PAGE_NUMBER_REGEX = /\/\d+$/;

export default function getPageNumberFromLocation(path: string): number {
    const index = path.search(SLASH_PAGE_NUMBER_REGEX);
    if (index === -1) {
        return 0;
    }
    return Number(path.slice(index).replace('/', '')) - 1;
}
