import { graphql } from '@server/gql';

export const getCartAddressQuery = graphql(`
    query getCartAddress(
        $cartId: String!
        $marketCode: String!
        $brandCode: String!
    ) {
        cartAddress(
            cartId: $cartId
            marketCode: $marketCode
            brandCode: $brandCode
        ) {
            customerDataShipTo {
                email
                phoneNumber
                cellPhoneNumber
                firstName
                lastName
                street
                addressLine2
                city
                region
                postalCode
                country
            }
            customerDataSellTo {
                email
                phoneNumber
                cellPhoneNumber
                firstName
                lastName
                street
                addressLine2
                city
                region
                postalCode
                country
            }
        }
    }
`);
