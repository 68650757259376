import type { CartItem, GetCartQuery } from '@server/gql/graphql';
import type { AddItemPayload } from 'types/cart';

import { OPTIMISTIC_CART_PROPS } from '@/utils/optimistic/const';
import variantToCartItem from '@/utils/optimistic/variantToCartItem';

export default function getOptimisticResponse(
    data: GetCartQuery | undefined,
    payload: AddItemPayload,
    mergeQuantity = false,
) {
    const existingItems: CartItem[] = data?.cart?.items ?? [];

    const optimisticItems = payload.map(({ item, quantity }) =>
        variantToCartItem(item, quantity),
    );

    const mergedItems = [
        ...existingItems.map((item) => {
            const optimisticItem = optimisticItems.find(({ partNo }) => {
                return partNo === item.partNo;
            });

            let quantity = optimisticItem?.quantity ?? 0;

            const isPayloadItem = payload.some(({ item: { id } }) => {
                return id === item.partNo;
            });

            if (mergeQuantity || !isPayloadItem) {
                quantity += item.quantity;
            }

            return {
                ...item,
                quantity,
            };
        }),
    ];

    const filteredOptimisticItems = optimisticItems.filter(({ partNo }) => {
        return !mergedItems.some((item) => partNo === item.partNo);
    });

    return {
        cart: {
            ...(data?.cart || OPTIMISTIC_CART_PROPS),
            items: [...mergedItems, ...filteredOptimisticItems],
        },
    };
}
