import { graphql } from '@server/gql';

export const getCheckoutQuery = graphql(/* GraphQL */ `
    query getCheckout($cartId: String!, $locale: String!) {
        checkout(locale: $locale, cartId: $cartId) {
            cart {
                ...cart
            }
            buyer {
                ...customer
            }
            payer {
                ...customer
            }
            shipTo {
                ...customer
            }
            payments {
                paymentCode
                amount
                cardNo
                paymentMethodId
                paymentServiceId
            }
        }
    }
`);
