import { SLASH_PAGE_NUMBER_REGEX } from '@server/utils/getPageNumberFromLocation';
import type { Filter } from '@server/utils/parseLoop/types';
import type { Sort } from 'types';

export const generateHash = (sort?: Sort, filters: Filter[] = []) => {
    if (typeof window === 'undefined') return '';
    const [urlQuery] = window.location.toString().split('#');
    const [url, query] = urlQuery.split('?');
    let cleanUrl = url.replace(SLASH_PAGE_NUMBER_REGEX, '');
    const output: string[] = [];
    if (sort !== undefined) output.push(`sort=${sort}`);
    for (let i = 0; i < filters.length; i += 1) {
        const filter = filters[i];
        if (filter.values?.length) {
            if (filter.type === 'checkbox')
                output.push(`${filter.attribute}=${filter.values?.join(',')}`);
            else if (
                filter.type === 'range' &&
                filter.min !== undefined &&
                filter.max !== undefined
            ) {
                if (
                    filter.values[0] > filter?.min ||
                    filter.values[1] < filter?.max
                )
                    output.push(
                        `${filter.attribute}=${filter.values?.join('-')}`,
                    );
            }
        }
    }
    if (query) cleanUrl += `?${query}`;
    if (output.length) cleanUrl += `#${output.join(';')}`;
    return cleanUrl;
};
