import { service } from '@server/client';
import type {
    ConfirmPaymentSuccessfulQuery,
    ConfirmPaymentSuccessfulQueryVariables,
} from '@server/gql/graphql';
import { confirmPaymentSuccessfulQuery } from '@server/queries/checkout';

export const confirmPaymentSuccessful = async (
    variables: ConfirmPaymentSuccessfulQueryVariables,
) => {
    try {
        const response = await service<
            ConfirmPaymentSuccessfulQuery,
            ConfirmPaymentSuccessfulQueryVariables
        >({
            query: confirmPaymentSuccessfulQuery,
            variables,
        });

        return response.confirmPaymentSuccessful;
    } catch (error: Error | unknown) {
        console.error('Error in confirmPaymentSuccessful', error);
        throw error;
    }
};
