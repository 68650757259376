import { service } from '@server/client';
import type {
    UpdateCheckoutMethodMutation,
    UpdateCheckoutMethodMutationVariables,
} from '@server/gql/graphql';
import { updateCheckoutMethodMutation } from '@server/queries/checkout';

export const updateCheckoutMethod = async (
    variables: UpdateCheckoutMethodMutationVariables,
) => {
    try {
        const response = await service<
            UpdateCheckoutMethodMutation,
            UpdateCheckoutMethodMutationVariables
        >({
            query: updateCheckoutMethodMutation,
            variables,
        });

        return response.checkout;
    } catch (error: Error | unknown) {
        console.error('Error in updateCheckoutMethod', error);
        throw error;
    }
};
