import type { Product, Variant } from 'types/product';

import { FEATURE_FLAGS } from '@/config/featureFlags';
import type { ProductQueries } from '@/utils/product/types';

/**
 * Select the current variant and product images based on given filter.
 * Replace the first image with the variant image if it exists.
 */
export const getCurrentVariantAndProductImages = (
    product: Product,
    variants: Variant[],
    filter: ProductQueries | undefined,
) => {
    let media = product.images ?? [];
    if (FEATURE_FLAGS.products.videos) {
        ({ media = [] } = product);
        const index = media.findIndex(({ type }) => type === 'video');
        if (index > -1 && index !== 2) {
            /**
             * FOR SOME REASON, VIDEO SHOULD ALWAYS BE AT POS 3 IN CAROUSEL
             * ¯\_(ツ)_/¯
             */
            const [video] = media.splice(index, 1);
            media.splice(2, 0, video);
        }
    }

    if (!filter?.variant)
        return {
            productImages: media,
            currentVariant: variants.length === 1 ? variants[0] : undefined,
        };
    const currentVariant =
        variants.find((variant) => variant.id === filter.variant) ??
        variants[0];
    return {
        productImages: [currentVariant?.image ?? media[0], ...media.slice(1)],
        currentVariant,
    };
};
