import { graphql } from '@server/gql';

export const addPromotionMutation = graphql(/* GraphQL */ `
    mutation addPromotionToCart(
        $discountCode: String!
        $cartId: String!
        $locale: String!
    ) {
        cart: addPromotionToCart(
            discountCode: $discountCode
            cartId: $cartId
            locale: $locale
        ) {
            ...cart
        }
    }
`);
