import { service } from '@server/client';
import type {
    UpdateCheckoutReceiverMutation,
    UpdateCheckoutReceiverMutationVariables,
} from '@server/gql/graphql';
import { updateCheckoutReceiverMutation } from '@server/queries/checkout';

export const updateCheckoutReceiver = async (
    variables: UpdateCheckoutReceiverMutationVariables,
) => {
    try {
        const response = await service<
            UpdateCheckoutReceiverMutation,
            UpdateCheckoutReceiverMutationVariables
        >({
            query: updateCheckoutReceiverMutation,
            variables,
        });

        return response.checkout;
    } catch (error: Error | unknown) {
        console.error('Error in updateCheckoutReceiver', error);
    }
};
