import getSearchEngineUrlByMarket from '@server/utils/getSearchEngineUrlByMarket';
import { PRODUCTS_ENDPOINT } from '@server/utils/parseLoop/const';
import parseVariant from '@server/utils/parseLoop/parseVariant';
import type {
    Loop54Payload,
    Loop54Response,
} from '@server/utils/parseLoop/types';
import { cache } from 'react';
import type { StructuredItem } from 'types/product';

import getSearchEngineUser from '@/utils/getSearchEngineUser';

export type Props = {
    items: StructuredItem[];
    market: string;
};

const fetchStructuredItems = cache(async (props: Props) => {
    const { market, items } = props;
    const payload: Loop54Payload = {
        customData: {
            market,
        },
        resultsOptions: {
            take: 50,
            skip: 0,
            facets: [],
            filter: {
                and: [
                    {
                        or: items.map((item) => ({
                            type: 'id',
                            value: item.partNo,
                        })),
                    },
                    { attributeName: `${market}_Price` },
                    { type: 'type', value: 'Variant' },
                ],
            },
        },
    };
    const response = await fetch(
        `${getSearchEngineUrlByMarket(props.market)}/${PRODUCTS_ENDPOINT}`,
        {
            method: 'POST',
            headers: {
                'Api-Version': 'V3',
                'User-Id': getSearchEngineUser(),
            },
            body: JSON.stringify(payload),
            cache: 'no-store',
        },
    );

    if (!response.ok) {
        return [];
    }

    const result: Loop54Response = await response.json();

    const variants = result.results.items.map(parseVariant);

    return variants.sort((a, b) => {
        const matchA = items.find((item) => item.partNo === a.id);
        const matchB = items.find((item) => item.partNo === b.id);
        return (matchA?.order ?? 0) - (matchB?.order ?? 0);
    });
});

export const preload = (props: Props) => {
    void fetchStructuredItems(props);
};

export default fetchStructuredItems;
