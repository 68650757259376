import type { Filter } from '@server/utils/parseLoop/types';

import { FEATURE_FLAGS } from '@/config/featureFlags';

import {
    DEFAULT_CATEGORY_ATTRIBUTE,
    DEFAULT_STATUSES_ATTRIBUTE,
} from './const';

const FILTER_TYPE_PRODUCT: Filter = {
    type: 'type',
    value: 'Product',
};

const FILTER_TYPE_EXPLODED: Filter = {
    type: 'attribute',
    attributeName: 'ShowAsProduct',
    value: true,
};

export const DEFAULT_PRODUCT_FILTER = FEATURE_FLAGS.searchEngine.exploded
    ? FILTER_TYPE_EXPLODED
    : FILTER_TYPE_PRODUCT;

export const DEFAULT_FILTER: Filter[] = [
    DEFAULT_PRODUCT_FILTER,
    {
        show: true,
        name: 'Category',
        type: 'checkbox',
        attribute: DEFAULT_CATEGORY_ATTRIBUTE,
    },
    {
        show: true,
        name: 'featured',
        type: 'checkbox',
        attribute: DEFAULT_STATUSES_ATTRIBUTE,
    },
];
