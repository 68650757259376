import { graphql } from '@server/gql';

export const getAdyenCheckoutFormQuery = graphql(/* GraphQL */ `
    query getAdyenCheckoutForm(
        $cartId: String!
        $localeCode: String!
        $returnURL: String!
        $marketCode: String!
        $brandCode: String!
    ) {
        adyenCheckoutForm(
            cartId: $cartId
            marketCode: $marketCode
            brandCode: $brandCode
            localeCode: $localeCode
            returnURL: $returnURL
        ) {
            id
            sessionData
            orderRef
        }
    }
`);
