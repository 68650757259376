import getSearchEngineUrlByMarket from '@server/utils/getSearchEngineUrlByMarket';
import applyLoopFilter from '@server/utils/parseLoop/applyLoopFilter';
import { DEFAULT_SORT, PRODUCTS_ENDPOINT } from '@server/utils/parseLoop/const';
import getFacets from '@server/utils/parseLoop/getFacets';
import parsePayload from '@server/utils/parseLoop/parseLoopPayload';
import type {
    BasicCommerceData,
    Filter,
    Loop54Filter,
    Loop54Payload,
} from '@server/utils/parseLoop/types';
import type { CommerceData, Sort } from 'types/common';
import type { LoopFacet } from 'types/loop54';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import { setLoopCacheOptions } from '@/utils/cache/setLoopCacheOptions';
import { DEFAULT_FILTER } from '@/utils/filters';

type Attribute = {
    name: string;
    value: string[];
};

type Props = {
    skip?: number;
    take?: number;
    sort?: Sort;
    attribute?: Attribute;
    market: string;
    applyCustomFilter?: Loop54Filter[];
    filters?: Filter[];
    commerceData?: CommerceData[];
    basicCommerceData?: BasicCommerceData | null;
    category?: string;
    slug?: string | null;
    defaultFilter?: any[];
    defaultSort?: Sort;
    searchEngineUser: string;
    facets?: LoopFacet[] | undefined;
    limitResponse?: boolean;
};

/**
 * Determines revalidation tags associated with a loop payload attribute.
 * If the attribute is a designer, brand, category, or flag(campaign/news), the appropriate
 * revalidation tags are added to the list.
 * @param attribute - The attribute to determine revalidation tags for.
 * @returns The revalidation tags associated with the attribute.
 */
const constructRevalidationTags = (attribute: Loop54Payload['attribute']) => {
    const tags: string[] = [REVALIDATION_TAGS.loopAll];
    if (!attribute?.name) return tags;

    const tagGenerators: Record<string, (value: string) => string> = {
        'Designers.Code': REVALIDATION_TAGS.designerByCode,
        'Brands.Code': REVALIDATION_TAGS.brandByCode,
        Category: REVALIDATION_TAGS.categoryByCode,
        Flags: () => REVALIDATION_TAGS.flags,
    };

    const generateTag = tagGenerators[attribute.name];
    if (!generateTag) return tags;

    const values = Array.isArray(attribute.value)
        ? attribute.value
        : [attribute.value];

    values.forEach((value) => {
        if (value) tags.push(generateTag(value.toString()));
    });

    return [...new Set(tags)]; // Removes duplicates
};

const fetchCategoryFromLoop = async (props: Props) => {
    const {
        filters = [],
        defaultFilter,
        defaultSort,
        facets,
        attribute,
        searchEngineUser,
        applyCustomFilter,
        ...rest
    } = props;
    const filtersPayload = [...(defaultFilter || DEFAULT_FILTER), ...filters];
    const sortPayload = defaultSort ?? DEFAULT_SORT;

    const { payload: tempPayload, filters: parsedFilters } = parsePayload({
        filters: filtersPayload,
        sort: sortPayload,
        ...rest,
    });

    const payload = applyCustomFilter
        ? applyLoopFilter(tempPayload, applyCustomFilter)
        : tempPayload;

    if (payload?.resultsOptions?.facets && facets) {
        const newFacets = getFacets({
            facets: payload?.resultsOptions.facets ?? [],
            givenFacets: facets,
        });
        // @ts-expect-error this is fine
        payload.resultsOptions.facets = newFacets;
    }

    let url = `${getSearchEngineUrlByMarket(
        props.market,
    )}/${PRODUCTS_ENDPOINT}`;

    const filteredPayload = payload;

    if (attribute) {
        filteredPayload.attribute = attribute;
    }

    if (payload.attribute) url += 'ByAttribute';
    const tags = constructRevalidationTags(filteredPayload.attribute);
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Api-Version': 'V3',
            'User-Id': searchEngineUser,
        },
        body: JSON.stringify({
            ...filteredPayload,
        }),
        ...setLoopCacheOptions({
            tags,
            revalidate: ONCE_PER_EIGHT_HOURS,
        }),
    });

    if (!response.ok) {
        throw new Error(
            `Network response was not ok [${payload.attribute?.value}]`,
        );
    }

    const items = await response.json();
    return { items, parsedFilters };
};

export default fetchCategoryFromLoop;
