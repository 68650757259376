import { graphql } from '@server/gql';

export const updateCheckoutMethodMutation = graphql(/* GraphQL */ `
    mutation updateCheckoutMethod(
        $cartId: String!
        $locale: String!
        $methodId: Int!
    ) {
        checkout: updateCheckoutMethod(
            locale: $locale
            cartId: $cartId
            methodId: $methodId
        ) {
            cart {
                id
            }
            buyer {
                ...customer
            }
            payer {
                ...customer
            }
            shipTo {
                ...customer
            }
            payments {
                paymentCode
                amount
                cardNo
                paymentMethodId
                paymentServiceId
            }
        }
    }
`);
