import { graphql } from '@server/gql';

export const updateCartMutation = graphql(/* GraphQL */ `
    mutation updateCart(
        $cartId: String!
        $locale: String!
        $cartItem: CartItemInput!
    ) {
        cart: updateCart(
            cartId: $cartId
            locale: $locale
            cartItem: $cartItem
        ) {
            ...cart
        }
    }
`);
