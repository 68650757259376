import { graphql } from '@server/gql';

export const setDeliveryOptionMutation = graphql(/* GraphQL */ `
    mutation setDeliveryOption(
        $locale: String!
        $cartId: String!
        $zipCode: String!
        $priceValue: Float!
        $deliveryOptionId: String!
        $deliveryAgentId: String
        $deliveryOptionName: String
    ) {
        setDeliveryOption(
            locale: $locale
            cartId: $cartId
            zipCode: $zipCode
            priceValue: $priceValue
            deliveryOptionId: $deliveryOptionId
            deliveryAgentId: $deliveryAgentId
            deliveryOptionName: $deliveryOptionName
        ) {
            id
        }
    }
`);
