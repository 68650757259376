import { graphql } from '@server/gql';

export const getCheckoutFormQuery = graphql(/* GraphQL */ `
    query getCheckoutForm(
        $cartId: String!
        $locale: String!
        $type: String!
        $checkoutSettings: CheckoutSettings!
        $methodId: Int!
    ) {
        checkoutForm(
            locale: $locale
            cartId: $cartId
            type: $type
            checkoutSettings: $checkoutSettings
            methodId: $methodId
        ) {
            status
            cartId
            formHtml
            orderNumber
        }
    }
`);
