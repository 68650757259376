import { graphql } from '@server/gql';

export const addGiftCardMutation = graphql(/* GraphQL */ `
    mutation addGiftCard(
        $amount: Float!
        $cartId: String!
        $locale: String!
        $giftCard: GiftCard!
    ) {
        addGiftCard(
            amount: $amount
            cartId: $cartId
            giftCard: $giftCard
            locale: $locale
        ) {
            amount
            paymentCode
        }
    }
`);
