import type { CartItem } from '@server/gql/graphql';
import type { Variant } from 'types';

import { OPTIMISTIC_CART_ITEMS } from '@/utils/optimistic/const';

export default function variantToCartItem(
    variant: Partial<Variant>,
    quantity: number,
): CartItem {
    if (!variant.id)
        throw new Error('Error in variantToCartItem: invalid variant id');

    return {
        ...OPTIMISTIC_CART_ITEMS,
        quantity,
        partNo: variant.id,
    };
}
