import { FEATURE_FLAGS } from '@/config/featureFlags';

/**
 * Will return the cache options if the cache feature flag is enabled.
 * Otherwise we disable caching.
 */
export const setLoopCacheOptions = ({
    tags,
    revalidate,
}: {
    tags: string[];
    revalidate: number;
}): Partial<RequestInit> => {
    if (FEATURE_FLAGS.cache.loop) {
        return { next: { revalidate, tags } };
    }
    return { cache: 'no-store' };
};
