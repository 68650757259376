import { SEARCH_ENDPOINT } from '@server/parsers/loop54';
import getSearchEngineUrlByMarket from '@server/utils/getSearchEngineUrlByMarket';
import parsePayload from '@server/utils/parseLoop/parseLoopPayload';
import { parseLoopResponse } from '@server/utils/parseLoop/parseLoopResponse';
import type { CommerceData, Filter } from '@server/utils/parseLoop/types';
import { cache } from 'react';
import type { Sort } from 'types';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import { DEFAULT_FILTER } from '@/utils/filters';

export interface SearchProductsProps {
    query?: string;
    skip?: number;
    take?: number;
    sort?: Sort;
    market: string;
    filters?: Filter[];
    commerceData?: CommerceData[];
    category?: string;
    searchEngineUser: string;
}

const searchProducts = cache(async (props: SearchProductsProps) => {
    if (!props.query)
        return {
            count: 0,
            items: [],
            filters: [],
        } as any;
    const { filters: _filters = [], searchEngineUser, ...rest } = props;
    const { payload, filters } = parsePayload({
        filters: [...DEFAULT_FILTER, ..._filters],
        ...rest,
    });

    const url = `${getSearchEngineUrlByMarket(
        props.market,
    )}/${SEARCH_ENDPOINT}#query=${props.query}`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Api-Version': 'V3',
            'User-Id': searchEngineUser,
        },
        body: JSON.stringify(payload),
        next: {
            tags: [REVALIDATION_TAGS.loopAll, REVALIDATION_TAGS.flags],
            revalidate: ONCE_PER_EIGHT_HOURS,
        },
    });
    const items = await response.json();

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return parseLoopResponse(items, filters);
});

export const preload = (props: SearchProductsProps) => {
    void searchProducts(props);
};

export default searchProducts;
