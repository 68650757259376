import type {
    BasicCommerceData,
    CommerceData,
    Filter,
    Loop54Facet,
    Loop54Filter,
    Loop54Payload,
    Loop54Sortby,
} from '@server/utils/parseLoop/types';
import type { Sort } from 'types';

import { DEFAULT_CATEGORY_ATTRIBUTE } from '@/utils/const';
import { DEFAULT_PRODUCT_FILTER } from '@/utils/filters';

const commerceDataToLoopAttributeName = (type: string) => {
    switch (type) {
        case 'Categories':
            return DEFAULT_CATEGORY_ATTRIBUTE;
        case 'Brands':
            return 'Brands.Code';
        case 'Designers':
            return 'Designers.Code';
        case 'Flags':
            return 'Flags';
        case 'Parametrics':
            return 'type';
        case 'itemNumbers':
            return 'Id';
        default:
            return '';
    }
};

const mergedFilters = (filters: Filter[]) =>
    filters.reduce((a, b) => {
        if (b.type === 'type' || b.type === 'attribute') {
            const index = a.findIndex((c) => c.type === b.type);
            if (index === -1) return [...a, b];
        } else if (b.type === 'id') {
            const index = a.findIndex((c) => c.type === b.type);
            if (index === -1) return [...a, b];
            const [current] = a.splice(index, 1);
            if (current.type === 'id')
                a.splice(index, 0, {
                    ...current,
                    ...b,
                    value: [...current.value, ...b.value],
                });
            else a.splice(index, 0, current);
        } else if (b.type === 'checkbox') {
            const index = a.findIndex((c) => c.attribute === b.attribute);
            if (index === -1) return [...a, b];
            const [current] = a.splice(index, 1);
            if (current.type === 'checkbox') {
                a.splice(index, 0, {
                    ...b,
                    items: b.items ?? [],
                    values: [...(current?.values ?? []), ...(b?.values ?? [])],
                });
            } else a.splice(index, 0, current);
        } else if (b.type === 'range') {
            const index = a.findIndex((c) => c.attribute === b.attribute);
            if (index === -1) return [...a, b];
            const [current] = a.splice(index, 1);
            if (current.type === 'range') {
                if (!b.values && current.values) {
                    a.splice(index, 0, current);
                } else {
                    a.splice(index, 0, b);
                }
            } else a.splice(index, 0, current);
        }
        return a;
    }, [] as Filter[]);

const parseCommerceData = (commerceData?: CommerceData[]): Filter[] => {
    if (!commerceData?.length) return [];
    const filters: Filter[] = [];
    for (let i = 0; i < commerceData.length; i += 1) {
        const data = commerceData[i];
        let values = [];
        const attribute = commerceDataToLoopAttributeName(
            data.commerceDataType,
        );
        if (data.commerceDataType === 'itemNumbers')
            values = data.items.filter(Boolean);
        else values = [data.item.Code];
        filters.push({
            show: false,
            type: 'checkbox',
            values,
            attribute,
            items: [],
            exclude: data.type === 'Exclude',
        });
    }
    return mergedFilters(filters);
};

const parseBasicCommerceData = (commerceData?: BasicCommerceData): Filter[] => {
    if (!commerceData) return [];
    const filters: Filter[] = [];
    const attribute = commerceDataToLoopAttributeName(
        commerceData.commerceType,
    );
    const values = [commerceData.commerceId];
    filters.push({
        show: false,
        type: 'checkbox',
        values,
        attribute,
        items: [],
    });
    return mergedFilters(filters);
};

const parseLocalFilters = (
    filters: Filter[],
    market: string,
): { facets: Loop54Facet[]; filter: Loop54Filter } => {
    const facets: Loop54Facet[] = [];
    const filter: Loop54Filter[] = [];

    for (let i = 0; i < filters.length; i += 1) {
        const current = filters[i];
        if (current.show) {
            if (current.type === 'checkbox') {
                facets.push({
                    name: current.name,
                    attributeName: current.attribute,
                    selected: current.values ?? [],
                    type: 'distinct',
                    sortBy: [
                        {
                            type: 'item',
                            order: 'asc',
                        },
                    ],
                });
            } else if (current.type === 'range') {
                let selected;
                if (
                    current.values !== undefined &&
                    current.min !== undefined &&
                    current.max !== undefined &&
                    (current.values[0] > current.min ||
                        current.values[1] < current.max)
                ) {
                    selected = {
                        min: current.values[0],
                        max: current.values[1],
                    };
                }
                facets.push({
                    name: current.name,
                    attributeName: current.attribute,
                    selected,
                    type: 'range',
                });
            }
        } else if (current.exclude && current.values) {
            if (current.attribute === 'Type') {
                filter.push({
                    not: {
                        type: 'type',
                        value: current.values[0],
                    },
                });
            } else if (current.type === 'range') {
                filter.push({
                    not: {
                        and: [
                            {
                                value: current.values[0],
                                type: 'attribute',
                                attributeName: current.attribute,
                                comparisonMode: 'greaterThan',
                            } as Loop54Filter,
                            {
                                value: current.values[1],
                                type: 'attribute',
                                attributeName: current.attribute,
                                comparisonMode: 'lessThan',
                            } as Loop54Filter,
                        ],
                    },
                });
            } else if (current.values.length > 1) {
                filter.push({
                    not: {
                        or: current.values.map(
                            (value): Loop54Filter => ({
                                value,
                                type: 'attribute',
                                attributeName: current.attribute,
                                comparisonMode: 'equals',
                            }),
                        ),
                    },
                });
            } else {
                filter.push({
                    not: {
                        value: current.values[0],
                        type: 'attribute',
                        attributeName: current.attribute,
                        comparisonMode: 'equals',
                    },
                });
            }
        } else if (current.values) {
            if (current.type === 'range') {
                filter.push({
                    and: [
                        {
                            value: current.values[0],
                            type: 'attribute',
                            attributeName: current.attribute,
                            comparisonMode: 'greaterThan',
                        } as Loop54Filter,
                        {
                            value: current.values[1],
                            type: 'attribute',
                            attributeName: current.attribute,
                            comparisonMode: 'lessThan',
                        } as Loop54Filter,
                    ],
                });
            } else if (current.type === 'checkbox') {
                if (current.attribute === 'Type') {
                    filter.push({
                        or: current.values.map(
                            (value): Loop54Filter => ({
                                value,
                                type: 'type',
                            }),
                        ),
                    });
                } else if (current.attribute === 'Id') {
                    const nonNullValues = current.values.filter(Boolean);
                    if (nonNullValues.length) {
                        filter.push({
                            or: nonNullValues.map(
                                (value): Loop54Filter => ({
                                    value,
                                    type: 'id',
                                }),
                            ),
                        });
                    }
                } else {
                    filter.push({
                        or: current.values.map(
                            (value): Loop54Filter => ({
                                value,
                                type: 'attribute',
                                attributeName: current.attribute,
                                comparisonMode: 'equals',
                            }),
                        ),
                    });
                }
            }
        } else if (current.type === 'type') {
            filter.push({
                and: [
                    {
                        type: 'type',
                        value: current.value as string,
                        comparisonMode: 'equals',
                    },
                ],
            });
        } else if (current.type === 'attribute') {
            filter.push({
                and: [
                    {
                        type: 'attribute',
                        attributeName: current.attributeName,
                        value: current.value as boolean,
                    },
                ],
            });
        }
    }
    return {
        facets,
        filter: { and: [...filter, { attributeName: `${market}_Price` }] },
    };
};

export type Props = {
    skip?: number;
    take?: number;
    sort?: Sort;
    market: string;
    filters?: Filter[];
    category?: string;
    commerceData?: CommerceData[];
    basicCommerceData?: BasicCommerceData | null;
    query?: string;
    limitResponse?: boolean;
};

export default function parseLoopPayload(props: Props): {
    payload: Loop54Payload;
    filters: Filter[];
} {
    const {
        skip = 0,
        take = 60,
        sort,
        market,
        filters = [],
        category,
        commerceData,
        basicCommerceData,
        query,
        limitResponse,
    } = props;

    const output: Loop54Payload = { customData: { market, limitResponse } };

    let commerce: Filter[];

    if (basicCommerceData) {
        commerce = parseBasicCommerceData(basicCommerceData);
    } else {
        commerce = parseCommerceData(commerceData);
    }

    if (
        commerce.some(
            (c) => c.attribute === DEFAULT_CATEGORY_ATTRIBUTE && !c.exclude,
        )
    ) {
        const index = commerce.findIndex(
            (c) => c.attribute === DEFAULT_CATEGORY_ATTRIBUTE,
        );
        const [attribute] = commerce.splice(index, 1);
        if (attribute.type === 'checkbox') {
            output.attribute = {
                name: attribute.attribute,
                value: category ?? attribute.values,
            };
        }
    } else if (
        commerce.some((c) => c.attribute === 'Brands.Code' && !c.exclude)
    ) {
        const index = commerce.findIndex((c) => c.attribute === 'Brands.Code');
        const [attribute] = commerce.splice(index, 1);
        if (attribute.type === 'checkbox') {
            output.attribute = {
                name: attribute.attribute,
                value: attribute.values,
            };
        }
    } else if (
        commerce.some((c) => c.attribute === 'Designers.Code' && !c.exclude)
    ) {
        const index = commerce.findIndex(
            (c) => c.attribute === 'Designers.Code',
        );
        const [attribute] = commerce.splice(index, 1);
        if (attribute.type === 'checkbox') {
            output.attribute = {
                name: attribute.attribute,
                value: attribute.values,
            };
        }
    } else if (commerce.some((c) => c.attribute === 'Flags' && !c.exclude)) {
        const index = commerce.findIndex((c) => c.attribute === 'Flags');
        const [attribute] = commerce.splice(index, 1);
        if (attribute.type === 'checkbox') {
            output.attribute = {
                name: attribute.attribute,
                value: attribute.values,
            };
            if (
                attribute.values?.length &&
                attribute.values.includes('202411-bw-outlet')
            ) {
                filters.push({
                    type: 'type',
                    value: 'Variant',
                });
            }
        }
    }

    if (query) {
        output.query = query;
    }

    const uniqueFilters = mergedFilters([
        ...commerce,
        ...filters,
        DEFAULT_PRODUCT_FILTER,
    ]);

    const sortBy: Loop54Sortby[] = [];

    if (sort) {
        switch (sort) {
            case 'newest': {
                const order = 'desc';
                sortBy.push(
                    {
                        order,
                        type: 'attribute',
                        attributeName: 'noga_published_date',
                    },
                    {
                        order,
                        type: 'attribute',
                        attributeName: 'published_date',
                    },
                );
                break;
            }
            case 'popularity':
            case 'relevance':
                sortBy.push({ type: sort, order: 'desc' });
                break;
            default:
                const [[first, ...rest], orderIndicator] = sort.split('_');
                const order = orderIndicator === 'desc' ? 'desc' : 'asc';
                const attributeName = [first.toUpperCase(), ...rest].join('');
                sortBy.push({ type: 'attribute', attributeName, order });
        }
    }

    const { facets, filter } = parseLocalFilters(uniqueFilters, market);
    output.resultsOptions = {
        facets,
        skip,
        take,
        sortBy,
        filter,
    };

    return {
        payload: output,
        filters: uniqueFilters,
    };
}
