import { service } from '@server/client';
import type {
    UpdateCheckoutBuyerMutation,
    UpdateCheckoutBuyerMutationVariables,
} from '@server/gql/graphql';
import { updateCheckoutBuyerMutation } from '@server/queries/checkout';

export const updateCheckoutBuyer = async (
    variables: UpdateCheckoutBuyerMutationVariables,
) => {
    try {
        const response = await service<
            UpdateCheckoutBuyerMutation,
            UpdateCheckoutBuyerMutationVariables
        >({
            query: updateCheckoutBuyerMutation,
            variables,
        });

        return response.checkout;
    } catch (error: Error | unknown) {
        console.error('Error in updateCheckoutBuyer', error);
    }
};
